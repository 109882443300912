import {
  ClearDelayItem,
  ClearDelayTableDataSource,
} from './clear-delay-datasource';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AttClearDelay } from '@attestis/clear-delay';
import * as moment from 'moment';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-clear-delay-form',
  templateUrl: './clear-delay-form.component.html',
  styleUrls: ['./clear-delay-form.component.scss'],
})
export class ClearDelayFormComponent implements AfterViewInit, OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<ClearDelayItem>;

  @ViewChild('calculateButton')
  calculateButtonRef: ElementRef<HTMLButtonElement>;

  public dataSource: ClearDelayTableDataSource;
  public formGroup = this.fb.group({
    addOrSubtract: ['1', Validators.required],
    date: [new Date(), Validators.required],
    delay: [2, Validators.required],
    delayUnit: ['M', Validators.required],
  });
  public atClearDelay = new AttClearDelay();
  public hasUnitNumber = false;
  public resultMoment: moment.Moment = moment();
  public delayUnits = [
    { name: 'jours', abbreviation: 'd' },
    { name: 'mois', abbreviation: 'M' },
    { name: 'année', abbreviation: 'y' },
  ];
  public displayedColumns = ['date', 'text'];

  public showAll: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    public snackBar: MatSnackBar,
    private renderer: Renderer2
  ) {}

  public ngOnInit() {
    this.dataSource = new ClearDelayTableDataSource();
    this.dataSource.data = [];
  }

  public ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  public onSubmit() {
    this.showAll = false;
    this.computeRows();
  }

  public computeRows() {
    const dateMoment = this.formGroup.get('date').value as moment.Moment;
    const delay = this.formGroup.get('delay').value;
    const unit = this.formGroup.get('delayUnit').value;

    try {
      if (this.formGroup.get('addOrSubtract').value === '1') {
        this.resultMoment = this.atClearDelay
          .setDate(dateMoment.toISOString())
          .addClearDelay(delay, unit);
      } else {
        this.resultMoment = this.atClearDelay
          .setDate(dateMoment.toISOString())
          .subtractClearDelay(delay, unit);
      }

      const length = this.atClearDelay.outputs.length;

      this.atClearDelay.outputs = this.atClearDelay.outputs.map(
        (output, index) => {
          if (index === length - 1) {
            // This is the last item, format with time
            return {
              ...output,
              date: output.moment.format('dddd DD MMMM YYYY à HH:mm'),
              text: output.text,
            };
          } else {
            // Not the last item, format without time
            return {
              ...output,
              date: output.moment.format('dddd DD MMMM YYYY'),
              text: output.text,
            };
          }
        }
      );

      if (this.atClearDelay.outputs.length > 6 && !this.showAll) {
        const dataArrayBuff = [...this.atClearDelay.outputs];
        this.dataSource.data = [
          ...dataArrayBuff.slice(0, 3),
          { date: '', text: ' ' },
          ...dataArrayBuff.slice(-3),
        ];
      } else {
        this.dataSource.data = [...this.atClearDelay.outputs];
      }
    } catch (error) {
      this.snackBar.open(error.message, 'Ok', {
        duration: 5000,
        panelClass: 'error',
      });
    }

    // Scroll to the first result
    this.focusFirstRow();
  }

  public toggleShowAll() {
    this.showAll = !this.showAll;
    this.computeRows();
  }

  public focusFirstRow() {
    const firstRow = document.querySelector('.result-list .mat-row');
    if (firstRow) {
      setTimeout(() => {
        this.renderer
          .selectRootElement(firstRow)
          .scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }
}
