<div class="container" fxLayout="column" fxLayoutAlign="center center">
  <div
    class="clear-delay-container"
    ngClass.lt-lg="lg"
    ngClass.lt-md="md"
    ngClass.lt-sm="sm"
    ngClass.lt-xs="xs"
  >
    <form [formGroup]="formGroup" novalidate (ngSubmit)="onSubmit()">
      <mat-card class="card">
        <h2>Calculatrice de délais francs</h2>
        <mat-card-content>
          <mat-radio-group
            fxLayout="column"
            fxLayoutGap="7px"
            class="radio-group"
            aria-label="Choisir une option"
            formControlName="addOrSubtract"
          >
            <mat-radio-button value="1" class="radio"
              >Calcul de la date de fin</mat-radio-button
            >
            <mat-radio-button value="2" class="radio"
              >Calcul de la date de début</mat-radio-button
            >
          </mat-radio-group>
          <div class="form-fields" ngClass.lt-md="small">
            <mat-form-field>
              <mat-label>Choisir une date</mat-label>
              <input
                matInput
                formControlName="date"
                [matDatepicker]="picker"
                (focus)="picker.open()"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="Valeur du délai"
                formControlName="delay"
                type="number"
                min="1"
                max="1000"
              />
            </mat-form-field>
            <mat-form-field>
              <mat-select
                placeholder="Unité du délai"
                formControlName="delayUnit"
              >
                <mat-option
                  *ngFor="let delayUnit of delayUnits"
                  [value]="delayUnit.abbreviation"
                >
                  {{ delayUnit.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="formGroup.controls['delayUnit'].hasError('required')"
              >
                L'unité du délai est <strong>requis</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-card-actions class="actions">
          <button
            mat-raised-button
            color="primary"
            type="submit"
            [disabled]="!formGroup.valid"
            class="calculate"
          >
            Calculer
          </button>
        </mat-card-actions>
      </mat-card>
    </form>

    <mat-card
      class="card"
      [ngClass]="{ hidden: atClearDelay.outputs.length === 0 }"
    >
      <mat-card-header>
        <mat-card-title *ngIf="formGroup.get('addOrSubtract').value === '1'"
          >Fin du délai franc le
          {{ resultMoment.format("dddd DD MMMM YYYY à HH:mm") }}</mat-card-title
        >
        <mat-card-title *ngIf="formGroup.get('addOrSubtract').value === '2'"
          >Début du délai franc le
          {{ resultMoment.format("dddd DD MMMM YYYY à HH:mm") }}</mat-card-title
        >
      </mat-card-header>
      <mat-card-content>
        <table mat-table matSort aria-label="Elements" class="result-list">
          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let row" class="date">{{ row.date }}</td>
          </ng-container>
          <!-- Explication Column -->
          <ng-container matColumnDef="text">
            <th mat-header-cell *matHeaderCellDef>Explication</th>
            <td mat-cell *matCellDef="let row" class="explication">
              {{ row.text }}
              <button
                *ngIf="row.text === ' '"
                mat-raised-button
                (click)="toggleShowAll()"
                (click)="focusFirstRow()"
              >
                Voir tous les jours
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
          #paginator
          [length]="dataSource?.data?.length"
          [pageIndex]="0"
          [pageSize]="100"
          [pageSizeOptions]="[25, 50, 100, 250]"
        >
        </mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
